import React from 'react'

import './Navbar.css'

import {MenuItems} from './MenuItems'

export default function Navbar() {
    return (
        <nav className="NavbarItem">
            <div className="navbar-left">
                <h1 className="navbar-logo">Rush to Prosperity</h1>
                <ul>
                    {MenuItems.map((item, index) => {
                        return (
                            <li key={index}><a href={item.url} className={item.cName}>{item.title}</a></li>
                        )
                    })}
                </ul>
            </div>
            <div className="navbar-right">
                <a href="#" className="btn-play">Play and Earn</a>
            </div>
        </nav>
    )
}
