export const MenuItems = [
    {
        title: 'Media',
        url: '/media',
        cName: 'nav-links'
    },
    {
        title: 'About us',
        url: '/about',
        cName: 'nav-links'
    }
]