import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Workplaces from './pages/Workplaces/Workplaces';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Inventory from './pages/Inventory/Inventory';
import Market from './pages/Market/Market';
import Research from './pages/Research/Research';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/workplaces" element={<Workplaces />} />
      <Route path="/inventory" element={<Inventory />} />
      <Route path="/market" element={<Market />} />
      <Route path="/research" element={<Research />} />
    </Routes>
</BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
