import React from 'react'
import { navMenu } from './navMenu'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Test from '../../../Inventory/Inventory'

import './footer.css'

export default function Footer() {


    return (
        <div className="footer">
        <nav>
            <div className="container">
                {/* <Router> */}
                <div className="nav-list">
                    <ul>
                         {
                            navMenu.map( link => (
                                <li>
                                    <img src={link.icon} alt={link.title} />
                                    <Link to={link.url}>{link.title}</Link>
                                </li>
                            ))
                         }
                         <Routes>
                             <Route path="/workplaces" element={Test}></Route>
                             <Route path="/inventory" component={Test}></Route>
                         </Routes>
                    </ul>
                </div>
                {/* </Router> */}
            </div>
        </nav>

    </div>
    )
}
