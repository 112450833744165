import React from 'react'

import StartLogo from '../../images/start_logo.png'

import { Link } from 'react-router-dom'

import './main.css'

export default function Main() {
    return (
        <main className="main">
            <div className="main-img"></div>
            <div className="main-up">
                <img src={StartLogo} alt="start-logo" />
                
                <div className="main-btn">
                <Link to="/workplaces" className="btn">Start Play</Link>
                </div>
            </div>
            
        </main>
    )
}
