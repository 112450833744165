import React from 'react'
import Navbar from './components/Navbar/Navbar'
import Main from './pages/Main/Main'

import './App.css';


function App() {
  return (
    <div className="App">
       <Navbar />
       <Main />
    </div>
  );
}

export default App;
