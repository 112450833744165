import workplacesIcon from '../../../../images/nav-icon/workplaces.png'
import inventoryIcon from '../../../../images/nav-icon/inventory.png'
import marketIcon from '../../../../images/nav-icon/market.png'
import researchIcon from '../../../../images/nav-icon/research.png'



export const navMenu = [
    {
        title: 'Workplaces',
        icon: workplacesIcon,
        url: '/workplaces'
    },
    {
        title: 'Inventory',
        icon: inventoryIcon,
        url: '/inventory'
    },
    {
        title: 'Market',
        icon: marketIcon,
        url: '/market'
    },
    {
        title: 'Research',
        icon: researchIcon,
        url: '/research'
    }
]